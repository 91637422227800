
import { Field } from "vee-validate";
import { computed, defineComponent, ref } from "vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import { CURRENCY, RESERVE_LINE_TYPE } from "@/core/config/Constant";
import Froala from "@/components/base/editor/Froala.vue";
import ReserveService from "@/core/services/ReserveService";
import store from "@/store";
import { exchangeRate } from "@/core/helpers/functions";

export default defineComponent({
  name: "ReserveForm",
  components: {Froala, BaseSelect, BaseForm, Field},
  props: {
    reserveId: {type: String, default: ''},
    reserve: {type: Object},
    isEdit: {type: Boolean, default: false}
  },
  emits: ['onSaved'],
  setup(props, {emit}) {
    const submitting = ref(false);
    const model = ref(props.reserve);
    const total = ref(0);
    const totalCAD = ref(0);
    const rates = computed(() => store.state.ExchangeRateModule.rates);
    const currencies = computed(() => store.state.ExchangeRateModule.currencies);
    const addLine = () => {
      model?.value?.lines.push({
        type: '',
        currency: 'CAD',
        value: 0.0,
        valueCAD: 0.0
      })
    }
    const removeLine = (line) => {
      if (model?.value?.lines) model.value.lines = model.value.lines.filter(item => item !== line)
      calculateTotal(null);
    }

    const calculateTotal = (line) => {
      if (line != null) {
        const rate = rates.value.find(item => item.base === 'CAD' && item.currency === line.currency);
        if (rate) {
          console.log(rate);
          line.valueCAD = exchangeRate(rate.rate, line.value, true).toFixed(2)
        } else {
          line.valueCAD = line.value
        }
        console.log(line.valueCAD);
      }
      total.value = model?.value?.lines.map(item => Number(item.value)).reduce((prev, curr) => prev + curr, 0);
      totalCAD.value = model?.value?.lines.map(item => Number(item.valueCAD)).reduce((prev, curr) => prev + curr, 0);
    }

    const submit = () => {
      submitting.value = true
      if (props.isEdit) {
        ReserveService.update(props.reserveId, model.value).then(res => {
          emit('onSaved', res);
        }).finally(() => {
          submitting.value = false;
        })
      } else {
        ReserveService.create(model.value).then(res => {
          emit('onSaved', res);
        }).finally(() => {
          submitting.value = false;
        })
      }
    }
    return {
      currencies,
      model,
      total,
      totalCAD,
      submitting,
      removeLine,
      calculateTotal,
      addLine,
      RESERVE_LINE_TYPE,
      CURRENCY,
      submit,
    }
  }
})
